.registration-thank-you-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.registration-thank-you-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-primary1-blue80);
}
.registration-thank-you-navbar-interactive {
  width: 90%;
  display: flex;
  position: sticky;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.registration-thank-you-logo {
  font-size: 2em;
  font-family: "Lato";
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}
.registration-thank-you-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.registration-thank-you-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.registration-thank-you-nav3 {
  display: none;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
}
.registration-thank-you-nav3:hover {
  color: #3d6e70ff;
}
.registration-thank-you-nav5 {
  display: none;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
}
.registration-thank-you-nav5:hover {
  color: #3d6e70ff;
}
.registration-thank-you-buttons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.registration-thank-you-burger-menu {
  display: none;
}
.registration-thank-you-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.registration-thank-you-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.registration-thank-you-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.registration-thank-you-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.registration-thank-you-logo1 {
  font-size: 2em;
  font-weight: bold;
}
.registration-thank-you-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.registration-thank-you-icon02 {
  fill: var(--dl-color-gray-900);
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.registration-thank-you-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.registration-thank-you-nav1 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.registration-thank-you-nav2 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.registration-thank-you-nav31 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.registration-thank-you-nav4 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.registration-thank-you-nav51 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.registration-thank-you-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.registration-thank-you-login {
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
}
.registration-thank-you-register {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
  background-color: var(--dl-color-primary1-blue100);
}
.registration-thank-you-register:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.registration-thank-you-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.registration-thank-you-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.registration-thank-you-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.registration-thank-you-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.registration-thank-you-text {
  font-size: 3rem;
  text-align: center;
}
.registration-thank-you-text1 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.registration-thank-you-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.registration-thank-you-footer1 {
  width: 100%;
  height: 606px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.registration-thank-you-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.registration-thank-you-logo2 {
  color: var(--dl-color-gray-white);
  font-size: 2em;
  font-weight: bold;
}
.registration-thank-you-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.registration-thank-you-nav11 {
  color: var(--dl-color-secondary-gray500);
  margin-left: 0px;
  text-decoration: none;
}
.registration-thank-you-nav21 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
}
.registration-thank-you-nav32 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
}
.registration-thank-you-nav41 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
}
.registration-thank-you-nav52 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
}
.registration-thank-you-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-800);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.registration-thank-you-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.registration-thank-you-text7 {
  color: var(--dl-color-secondary-gray500);
}
.registration-thank-you-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.registration-thank-you-icon10 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.registration-thank-you-icon12 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.registration-thank-you-icon14 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .registration-thank-you-text1 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .registration-thank-you-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .registration-thank-you-desktop-menu {
    display: none;
  }
  .registration-thank-you-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .registration-thank-you-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .registration-thank-you-nav1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .registration-thank-you-nav2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .registration-thank-you-nav31 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .registration-thank-you-nav4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .registration-thank-you-nav51 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .registration-thank-you-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .registration-thank-you-text1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .registration-thank-you-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .registration-thank-you-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .registration-thank-you-container2 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .registration-thank-you-text7 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .registration-thank-you-navbar-interactive {
    width: 90%;
    padding: var(--dl-space-space-unit);
  }
  .registration-thank-you-logo {
    font-size: 1.5em;
  }
  .registration-thank-you-mobile-menu {
    padding: 16px;
  }
  .registration-thank-you-logo1 {
    font-size: 1.5em;
  }
  .registration-thank-you-icon02 {
    fill: var(--dl-color-gray-900);
  }
  .registration-thank-you-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .registration-thank-you-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .registration-thank-you-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .registration-thank-you-container2 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .registration-thank-you-text7 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
