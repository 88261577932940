.code-of-conduct-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}
.code-of-conduct-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    background-color: var(--dl-color-primary1-blue80);
}
.code-of-conduct-navbar-interactive {
    width: 90%;
    display: flex;
    position: sticky;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-threeunits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}
.code-of-conduct-logo {
    font-size: 2em;
    font-family: "Lato";
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
}
.code-of-conduct-desktop-menu {
    flex: 1;
    display: flex;
    justify-content: space-between;
}
.code-of-conduct-links {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.code-of-conduct-nav3 {
    display: none;
    transition: 0.3s;
    margin-left: var(--dl-space-space-twounits);
}
.code-of-conduct-nav3:hover {
    color: #3d6e70ff;
}
.code-of-conduct-nav5 {
    display: none;
    transition: 0.3s;
    margin-left: var(--dl-space-space-twounits);
}
.code-of-conduct-nav5:hover {
    color: #3d6e70ff;
}
.code-of-conduct-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.code-of-conduct-login {
    border-color: var(--dl-color-primary1-blue100);
    border-width: 1px;
    margin-right: var(--dl-space-space-twounits);
    padding-left: 1.5rem;
    border-radius: 45px;
    padding-right: 1.5rem;
    text-decoration: none;
}
.code-of-conduct-register {
    color: var(--dl-color-gray-white);
    transition: 0.3s;
    font-weight: bold;
    border-color: var(--dl-color-primary1-blue100);
    padding-left: 1.5rem;
    border-radius: 45px;
    padding-right: 1.5rem;
    text-decoration: none;
    background-color: var(--dl-color-primary1-blue100);
}
.code-of-conduct-register:hover {
    border-color: rgba(41, 20, 119, 0.9);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: rgba(41, 20, 119, 0.9);
}
.code-of-conduct-burger-menu {
    display: none;
}
.code-of-conduct-icon {
    width: var(--dl-size-size-xsmall);
    cursor: pointer;
    height: var(--dl-size-size-xsmall);
}
.code-of-conduct-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    padding: 32px;
    z-index: 100;
    position: absolute;
    border-radius: 50px;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}
.code-of-conduct-nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.code-of-conduct-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
}
.code-of-conduct-logo1 {
    font-size: 2em;
    font-weight: bold;
}
.code-of-conduct-close-menu {
    display: flex;
    align-items: center;
    justify-content: center;
}
.code-of-conduct-icon02 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    cursor: pointer;
    height: var(--dl-size-size-xsmall);
}
.code-of-conduct-links1 {
    flex: 0 0 auto;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.code-of-conduct-nav1 {
    color: var(--dl-color-gray-black);
    margin-bottom: var(--dl-space-space-unit);
}
.code-of-conduct-nav2 {
    color: var(--dl-color-gray-black);
    margin-bottom: var(--dl-space-space-unit);
}
.code-of-conduct-nav31 {
    color: var(--dl-color-gray-black);
    margin-bottom: var(--dl-space-space-unit);
}
.code-of-conduct-nav4 {
    color: var(--dl-color-gray-black);
    margin-bottom: var(--dl-space-space-unit);
}
.code-of-conduct-nav51 {
    color: var(--dl-color-gray-black);
    margin-bottom: var(--dl-space-space-unit);
}
.code-of-conduct-buttons1 {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.code-of-conduct-login1 {
    border-color: var(--dl-color-primary1-blue100);
    border-width: 1px;
    margin-right: var(--dl-space-space-twounits);
    padding-left: 1.5rem;
    border-radius: 30px;
    padding-right: 1.5rem;
}
.code-of-conduct-register1 {
    color: var(--dl-color-gray-white);
    transition: 0.3s;
    border-color: var(--dl-color-primary1-blue100);
    border-width: 1px;
    padding-left: 1.5rem;
    border-radius: 30px;
    padding-right: 1.5rem;
    background-color: var(--dl-color-primary1-blue100);
}
.code-of-conduct-register1:hover {
    border-color: #849492ff;
    background-color: #849492ff;
}
.code-of-conduct-icon04 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}
.code-of-conduct-icon06 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}
.code-of-conduct-icon08 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}
.code-of-conduct-hero {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--dl-color-primary1-blue80);
}
.code-of-conduct-hero1 {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    min-height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
}
.code-of-conduct-container1 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}
.code-of-conduct-hero-heading {
    color: var(--dl-color-gray-white);
    max-width: 800px;
    text-align: center;
    font-family: "Raleway";
    line-height: 1.6;
}
.code-of-conduct-hero-sub-heading {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
}
.code-of-conduct-btn-group {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: center;
    flex-direction: row;
}
.code-of-conduct-hero-button1 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    transition: 0.3s;
    font-weight: bold;
    padding-top: 1.5rem;
    border-color: var(--dl-color-primary1-blue100);
    border-width: 0px;
    padding-left: 3rem;
    border-radius: 45px;
    padding-right: 3rem;
    padding-bottom: 1.5rem;
    text-decoration: none;
    background-color: var(--dl-color-primary1-blue100);
}
.code-of-conduct-hero-button1:hover {
    color: var(--dl-color-gray-white);
    border-color: rgba(41, 20, 119, 0.9);
    background-color: rgba(41, 20, 119, 0.9);
}
.code-of-conduct-banner {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
.code-of-conduct-text {
    font-size: 3rem;
    text-align: center;
}
.code-of-conduct-text01 {
    max-width: var(--dl-size-size-maxwidth);
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
}
.code-of-conduct-features {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: var(--dl-size-size-maxwidth);
    flex-direction: column;
}
.code-of-conduct-text07 {
    font-size: 3rem;
    margin-bottom: var(--dl-space-space-threeunits);
}
.code-of-conduct-container2 {
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: 1fr 1fr;
}
.code-of-conduct-features1 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: var(--dl-size-size-maxwidth);
    flex-direction: column;
}
.code-of-conduct-text08 {
    font-size: 3rem;
    margin-bottom: var(--dl-space-space-threeunits);
}
.code-of-conduct-container3 {
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: 1fr 1fr;
}
.code-of-conduct-features2 {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: var(--dl-size-size-maxwidth);
    flex-direction: column;
}
.code-of-conduct-container4 {
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: 1fr 1fr;
}
.code-of-conduct-footer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dl-color-gray-black);
}
.code-of-conduct-footer1 {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fourunits);
    justify-content: space-between;
}
.code-of-conduct-container5 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}
.code-of-conduct-logo2 {
    color: var(--dl-color-gray-white);
    font-size: 2em;
    font-weight: bold;
}
.code-of-conduct-nav1 {
    flex: 0 0 auto;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    display: flex;
    margin-top: 0px;
    align-items: center;
    flex-direction: row;
}
.code-of-conduct-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    border-color: var(--dl-color-gray-800);
    border-style: solid;
    border-width: 1px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}
.code-of-conduct-container6 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.code-of-conduct-text09 {
    color: var(--dl-color-secondary-gray500);
}
.code-of-conduct-icon-group1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.code-of-conduct-icon10 {
    fill: var(--dl-color-secondary-gray500);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
}
.code-of-conduct-link {
    display: contents;
}
.code-of-conduct-icon12 {
    fill: var(--dl-color-secondary-gray500);
    width: 24px;
    height: 24px;
    text-decoration: none;
}
@media(max-width: 991px) {
    .code-of-conduct-hero1 {
        flex-direction: column;
    }
    .code-of-conduct-container1 {
        align-items: center;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
        padding-right: 0px;
    }
    .code-of-conduct-hero-heading {
        text-align: center;
    }
    .code-of-conduct-hero-sub-heading {
        text-align: center;
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
    }
    .code-of-conduct-text01 {
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .code-of-conduct-navbar-interactive {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }
    .code-of-conduct-desktop-menu {
        display: none;
    }
    .code-of-conduct-burger-menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .code-of-conduct-mobile-menu {
        padding-top: var(--dl-space-space-oneandhalfunits);
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
        padding-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .code-of-conduct-nav1 {
        margin-bottom: var(--dl-space-space-unit);
    }
    .code-of-conduct-nav2 {
        margin-left: 0;
        margin-bottom: var(--dl-space-space-unit);
    }
    .code-of-conduct-nav31 {
        margin-left: 0;
        margin-bottom: var(--dl-space-space-unit);
    }
    .code-of-conduct-nav4 {
        margin-left: 0;
        margin-bottom: var(--dl-space-space-unit);
    }
    .code-of-conduct-nav51 {
        margin-left: 0;
        margin-bottom: var(--dl-space-space-unit);
    }
    .code-of-conduct-hero1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }
    .code-of-conduct-hero-sub-heading {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }
    .code-of-conduct-banner {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }
    .code-of-conduct-text01 {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }
    .code-of-conduct-features {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }
    .code-of-conduct-container2 {
        grid-template-columns: 1fr;
    }
    .code-of-conduct-features1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }
    .code-of-conduct-container3 {
        grid-template-columns: 1fr;
    }
    .code-of-conduct-features2 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }
    .code-of-conduct-container4 {
        grid-template-columns: 1fr;
    }
    .code-of-conduct-footer1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }
    .code-of-conduct-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .code-of-conduct-container6 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }
    .code-of-conduct-text09 {
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}
@media(max-width: 479px) {
    .code-of-conduct-navbar-interactive {
        width: 90%;
        padding: var(--dl-space-space-unit);
    }
    .code-of-conduct-logo {
        font-size: 1.5em;
    }
    .code-of-conduct-mobile-menu {
        padding: 16px;
    }
    .code-of-conduct-logo1 {
        font-size: 1.5em;
    }
    .code-of-conduct-icon02 {
        fill: var(--dl-color-gray-900);
    }
    .code-of-conduct-hero1 {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }
    .code-of-conduct-container1 {
        margin-bottom: var(--dl-space-space-unit);
    }
    .code-of-conduct-btn-group {
        flex-direction: column;
    }
    .code-of-conduct-banner {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }
    .code-of-conduct-features {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }
    .code-of-conduct-features1 {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }
    .code-of-conduct-features2 {
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
        padding-bottom: var(--dl-space-space-twounits);
    }
    .code-of-conduct-footer1 {
        padding: var(--dl-space-space-unit);
    }
    .code-of-conduct-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .code-of-conduct-container6 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }
    .code-of-conduct-text09 {
        text-align: center;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}
